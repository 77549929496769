@font-face {
    font-family: star;
    src: url(starfont.ttf);
}
@font-face {
    font-family: street;
    src: url(../fonts/YdestreetB.ttf);
}   


.gridbox {
    color: #ccad67;
    font-family: 'street';
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    position: absolute;
}

.project {
    font-size: 20px;
    text-indent: 12%;
    font-family: 'star';
    color: #efd9ce;
}



.vidtitle {
    font-size: 12px;
    color: white;
    text-indent: 8%;
    font-family: 'streetBold';
};

.projects {
    font-size: 35px;
    font-family: 'streetBold';
    color: #f8f4f2;
    margin-left: -5%;
}

.currentproject {
    font-size: 20px;
    text-indent: 10%;
    font-family: 'star';
    color: #efd9ce;
}