

.container {
    display: flex;
}

.portfolio {
    /* this is needed or the background will be offset by a few pixels at the top */
    overflow:visible;
    position: relative;
  }
  
  .portfolio::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    padding-left: 9%;
    width: 91%;
    height: 100vh;
    background-image:
    url(portbackground.jpg);
  background-size: cover;
  background-blend-mode: saturation;
  min-height: 100%;
  height: 102vh;
    filter: blur(10px);
  }