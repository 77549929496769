@font-face {
    font-family: star;
    src: url(starfont.ttf);
}
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital@1&family=Inconsolata:wght@300;600&family=Roboto:wght@300&display=swap');



.jobdesc {
    border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: black;
    z-index: 5;
    position: relative;
    margin-top: -91.5%;
    margin-left: 13%;
    text-align: center;
    font-family: 'Inconsolata', monospace;
    font-weight: bolder;
    font-size: 12px;
}

p {
    margin-top: -0%;
    margin-bottom: 0%;
}

.iconcontainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 81%;
    margin-top: -16.5%;
    gap: 18px;
}


.name {
    text-indent: -30%;
    font-size: 25px;
}


.icons:hover {
cursor: pointer;
}