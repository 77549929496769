@import url(../assets.css);

.creditscontainer {
    float: left;
    position: relative;
    height: 98vh;
    width: 82vw;
    border-radius: 30px;
    background-color: #f2f5f6;
    margin-left: 12%;
    margin-top: 3%;
}

.creditsgrid {
    margin-top: 1%;
}

.creditstitle {
    font-size: clamp(20px, 40px, 3vw);
    font-weight: bolder;
    text-align: center;
}

.sectionheader {
    font-size: clamp(17px, 25px, 2vw);
    font-weight: bold;
}

.stackgrid {
    margin-left: 5px;
}