@font-face {
    font-family: repet;
    src: url(./fonts/repet.ttf);
}

.link {
  display: flex;
}

.status {
    color: yellowgreen;
}

.sidebar{
    font-family: repet;
    height: 100%;
    padding: 10px 10px;
    box-shadow: 0 0 0.7px white;
    background-color: transparent;
    position:absolute;
    color: beige;
    background-image: url(./background.jpg);
    background-size: cover;
    background-position: bottom;
    z-index: 1600;
}

  .title {
      overflow: hidden;
      padding-left: 0px;
      padding-top: 10px;
      font-size: 30px;
  }


  .icon{
    margin-left: -10vw;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 18px;
    padding-bottom: 18px;
    text-align:center;
  }

  .icon:hover{
    color: rgba(26, 180, 194, 0.5);
    transition: color 100ms ease-out;
  }


  @media only screen and (max-width: 500px) {
    .sidebar {
      width: 7%;
      box-shadow: 0 0 0.7px white;
    }

    .link {
      display: none;
    }
  }

  @media only screen and (min-width: 500px) {
    .sidebar {
      width: 7%;
      box-shadow: 0 0 0.7px white;
    }

    .extended .icon {
       visibility: hidden;
    }
  }

  @media only screen and (min-width: 500px) {
    .icon {
      display: none;
    }
  }

