

.link{
    justify-content: right;
    display: flex;
    align-items: cover;
    cursor: pointer;
    max-width: 100%;
    text-align: end;
  }
  .link:hover{
    color: rgba(26, 180, 194, 0.5);
    transition: color 100ms ease-out;
  }
  .link > h2{
    font-weight: 700;
    font-size: 20px;
    margin-right: 3%;
  }

  @media only screen and (max-width: 420px) {
    .linktext {
      display: none;
    }
  }

  @media only screen and (min-width: 420px) {
    .icon {
      display: block;
    }
  }
