.App {
  text-align: center;
}

body {
  background-image: url(./background.jpg);
    background-size: cover;
    background-position: top;
    min-height: 100vh;
    min-width: 100vw;
    position: relative;
}

.content {
 display: grid;
 
}